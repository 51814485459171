@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
.index {
	.visual {
		background: url(/common/img/index/bg_visual_pc.png) center center no-repeat;
		background-size: cover;
		&__inner {
			max-width: 1040px;
			margin: 0 auto;
			padding: 65px 20px 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			color: $white;
			font-size: 1.7rem;
			&__logo {
				width: 100%;
				text-align: center;
				&__img {
					max-width: 770px;
					margin: 0 0 40px;
				}
			}
			&__text {
				text-align: center;
			}
			&__btn {
				.btn {
					flex-direction: column;
					height: 90px;
					min-width: 400px;
					@include radius(45);
					margin: 0 10px;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 320px;height: 80px; }
					&__ttl {
						font-size: 2.2rem;
						font-weight: $font-medium;
						margin: 0;
						i {
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
	.bg-cam {
		background: url(/common/img/index/bg_index01.png) center center repeat;
	}
	.gift {
		background: $white;
		padding: 0 0 50px;
		&__head {
			font-size: 3.2rem;
			font-weight: $font-bold;
			height: 70px;
			line-height: 70px;
			background-size: cover;
			text-align: center;
		}
		&--car {
			.gift__head {
				background: url(/common/img/index/bg_car_head_01.png) center center no-repeat;
				color: $blue;
			}
		}
		&--seimei {
			.gift__head {
				background: url(/common/img/index/bg_seimei_head_01.png) center center no-repeat;
				color: $white;
			}
		}
		&__body {
			padding: 25px 50px 0;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 25px 25px 0;}
			text-align: center;
			&__catch {
				font-size: $xxl;
				font-weight: $font-bold;
				display: inline-block;
				background: linear-gradient(transparent 80%, #FFE435 0%);
				line-height: 1.0;
				padding: 0 0 5px;
				margin: 0 0 35px;
				&--people {
					color: $red;
					font-size: 2.5rem;
				}
				&--number {
					color: $red;
					font-size: 3.9rem;
				}
			}
			&__title {
				padding: 5px 0;
				color: $white;
				font-size: $l;
				font-weight: $font-medium;
				.icon-midashi {
					margin-right: 5px;
				}
				.icon-midashi2 {
					margin-left: 5px;
				}
			}
			&__item {
				&__img {
					margin: 0 0 10px;
					max-width: 100%;
				}
				&__text {
					display: flex;
					align-items: center;
					&__icon {
						width: 80px;
						height: 80px;
						flex: 0 1 80px;
						display: flex;
						justify-content: center;
						flex-direction: column;
						align-items: center;
						background: $orange;
						border-radius: 40px;
						color: $white;
						font-size: 1.3rem;
						line-height: 1.0;
						margin: 0 10px 0 0;
						@media screen and (min-width:  767.5px) and (max-width:1024px) { margin: 0 5px 0 0; }
						&__people {
							font-size: 3.6rem;
							font-weight: $font-bold;
						}
					}
					&__title {
						text-align: left;
						line-height: 1.3;
						font-size: 1.9rem;
						font-weight: $font-bold;
						@media screen and (min-width:  767.5px) and (max-width:1024px) { font-size: 1.7rem; }
						&__maker {
							font-size: $m;
							font-weight: $font-medium;
						}
						&__price {
							font-size: 2.6rem;
							color: $red;
							font-weight: $font-black;
						}
						&__pricelabel {
							color: $red;
							font-size: $m;
						}
					}
				}
			}
			&--seimei {
				&__inner {
					display: flex;
					&--award {
						padding: 0 29px 0 0;
						border-right: 1px dashed #DFDFDF;
						width: calc((100% / 3) * 2);
						.gift__body__title {
							background: $orange;
						}
					}
					&--w-chance {
						padding: 0 0 0 27px;
						width: calc(100% / 3);
						.gift__body__title {
							background: $baseColor;
						}
						.gift__body__catch {
							margin: 0 0 29px;
							font-size: 1.7rem;
							&--number {
								font-size: 2.5rem;
							}
							&--people {
								font-size: 1.7rem;
							}
							&--sub {
								font-size: 1.5rem;
								margin: 0;
							}
						}
						.gift__body__item__text__icon {
							background: $baseColor;
						}
					}
				}
			}
			&__note {
				color: #686868;
				text-align: left;
				font-size: 1.5rem;
			}
		}	
	}
}