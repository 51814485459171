@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
.index {
	.visual {
		background: url(/common/img/index/bg_visual_sp.png) center center no-repeat;
		background-size: cover;
		&__inner {
			max-width: 100%;
			margin: 0 auto;
			padding: 30px 10px 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			color: $white;
			font-size: 1.7rem;
			&__logo {
				width: 100%;
				text-align: center;
				&__img {
					max-width: 75%;
					margin: 0 auto 20px;
				}
			}
			&__text {
				text-align: center;
				font-size: 1.5rem;
			}
			&__btn {
				display: flex;
				.btn {
					min-width: auto;
					flex-direction: column;
					@include radius(20);
					height: auto;
					margin: 0 10px;
					padding: 20px 10px 35px;
					font-size: $s;
					&__ttl {
						.icon-car,
						.icon-seimei {
							display: block;
							font-size: 2.2rem;
							margin-bottom: 10px;
						}
						font-size: $l;
						font-weight: $font-medium;
						margin: 0 0 5px;
					}
					.icon-fix-right {
						display: block;
						@include centering-elements(true, false);
						bottom: 10px;
						top: auto;
						right: auto;
					}
				}
			}
		}
	}	
	.bg-cam {
		background: url(/common/img/index/bg_index01.png) center center repeat;
	}
	.gift {
		background: $white;
		padding: 0 0 30px;
		&__head {
			font-size: 2.6rem;
			font-weight: $font-bold;
			height: 60px;
			line-height: 60px;
			background-size: cover;
			text-align: center;
		}
		&--car {
			.gift__head {
				background: url(/common/img/index/bg_car_head_01.png) center center no-repeat;
				background-size: cover;
				color: $blue;
			}
		}
		&--seimei {
			.gift__head {
				background: url(/common/img/index/bg_seimei_head_01.png) center center no-repeat;
				background-size: cover;
				color: $white;
			}
		}
		&__body {
			padding: 25px 25px 0;
			text-align: center;
			&__catch {
				font-size: 1.7rem;
				font-weight: $font-bold;
				line-height: 1.2;
				margin: 0 0 25px;
				&--people {
					color: $red;
					font-size: $xl;
				}
				&--number {
					color: $red;
					font-size: 3.2rem;
				}
			}
			&__title {
				padding: 5px 0;
				margin: 0 0 10px;
				color: $white;
				font-size: $l;
				font-weight: $font-medium;
				.icon-midashi {
					margin-right: 5px;
				}
				.icon-midashi2 {
					margin-left: 5px;
				}
			}
			&__item {
				margin-bottom: 40px;
				&:last-child {
					margin-bottom: 0;
				}
				&__img {
					margin: 0 0 10px;
					max-width: 100%;
				}
				&__text {
					display: flex;
					align-items: center;
					justify-content: center;
					&__icon {
						width: 80px;
						height: 80px;
						flex: 0 1 80px;
						display: flex;
						justify-content: center;
						flex-direction: column;
						align-items: center;
						background: $orange;
						border-radius: 40px;
						color: $white;
						font-size: 1.3rem;
						line-height: 1.0;
						margin: 0 10px 0 0;
						@media screen and (min-width:  767.5px) and (max-width:1024px) { margin: 0 5px 0 0; }
						&__people {
							font-size: 3.6rem;
							font-weight: $font-bold;
						}
					}
					&__title {
						text-align: left;
						line-height: 1.3;
						font-size: 1.9rem;
						font-weight: $font-bold;
						@media screen and (min-width:  767.5px) and (max-width:1024px) { font-size: 1.7rem; }
						&__maker {
							font-size: $m;
							font-weight: $font-medium;
						}
						&__price {
							font-size: 2.6rem;
							color: $red;
							font-weight: $font-black;
						}
						&__pricelabel {
							color: $red;
							font-size: $m;
						}
					}
				}
			}
			&--seimei {
				&__inner {
					&--award {
						padding: 0 0 30px;
						border-bottom: 1px dashed #DFDFDF;
						.gift__body__title {
							background: $orange;
						}
					}
					&--w-chance {
						padding: 30px 0 0;
						.gift__body__title {
							background: $baseColor;
						}
						.gift__body__catch {
							&--sub {
								font-size: 1.5rem;
								margin: 0;
							}
						}
						.gift__body__item__text__icon {
							background: $baseColor;
						}
					}
				}
			}
			&__note {
				color: #686868;
				text-align: left;
				font-size: $s;
			}
		}	
	}
}