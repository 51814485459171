@charset "utf-8";
/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?f5ro28');
  src:  url('/common/icon/fonts/icomoon.eot?f5ro28#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?f5ro28') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?f5ro28') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?f5ro28') format('woff'),
    url('/common/icon/fonts/icomoon.svg?f5ro28#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-midashi2:before {
  content: "\e904";
}
.icon-midashi:before {
  content: "\e903";
}
.icon-seimei:before {
  content: "\e902";
}
.icon-car:before {
  content: "\e901";
}
.icon-estimate:before {
  content: "\e900";
}
.icon-check:before {
  content: "\f00c";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
