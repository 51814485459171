@charset "utf-8";
/* ============================================================ */
/* seimei-form.html */
/* ============================================================ */
.seimei-form {
	.hosyo-list {
		&__item {
			background: $white;
			text-decoration: none;
			color: $textColor;
			position: relative;
			padding: 10px 30px 10px 10px;
			&__img {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin: 0 0 10px;
				&__icon {
					width: 37px!important;
					height: 43px;
					margin: 0 10px 0 0;
				}
				&__logo {
					height: 23px;
					width: auto!important;
					&--life {
						height: 33px;
					}
				}
			}
			.icon-angle-right {
				background: $baseColor;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
				@include centering-elements(false, true);
				right: 10px;
				font-weight: $font-bold;
				font-size: $s;
			}
			&__text {
				margin-bottom: 0;
			}
		}
	}
}