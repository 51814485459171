@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer-bnr {
	&__inner {
		max-width: 100%;
		margin: 0 auto;
		padding: 40px 15px 25px;
		&__item {
			margin: 0 auto 15px;
			max-width: 400px;
		}
	}
}
.footer-nav {
	&__inner {
		&__list {
			&__item {
				width: 100%;
				border-bottom: 1px solid $borderColor;
				&__link {
					line-height: 1.0;
					padding: 15px;
					color: #2E2E2E;
					display: block;
					text-decoration: none;
					position: relative;
					.icon-angle-right {
						@include centering-elements(false, true);
						right: 15px;
					}
				}
			}
		}
	}
}
footer {
	background: $baseColor;
	text-align: center;
	line-height: 1.6;
	padding: 30px 0 0;
	font-size: 1.5rem;
	color: #F9F9F9;

	.footer-logo {
		margin: 0 0 25px;
		&__link {
			display: block;
			width: 210px;
			margin: 0 auto 15px;
		}
	}
	.footer-address {
		margin: 0 0 30px;
		&__link {
			color: #F9F9F9;
		}
	}

	.copyright {
		background: $baseColor;
		border-top: 1px solid #1F8576;
		padding: 20px 0;
		color: $white;
		font-size: $xxs;
	}
}