@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer-bnr {
	&__inner {
		max-width: 1040px;
		margin: 0 auto;
		padding: 70px 20px 55px;
		.column {
			justify-content: center;
		}
	}
}
.footer-nav {
	&__inner {
		max-width: 1040px;
		margin: 0 auto;
		padding: 40px 20px 25px;
		&__list {
			display: flex;
			justify-content: center;
			margin: 0 0 15px;
			&__item {
				&__link {
					line-height: 1.0;
					border-right: 1px solid $borderColor;
					padding: 0 15px;
					color: #2E2E2E;
					display: block;
				}
				&:first-child {
					.footer-nav__inner__list__item__link {
						border-left: 1px solid $borderColor;
					}
				}
			}
		}
	}
}
footer {
	background: $baseColor;
	text-align: center;
	line-height: 1.6;
	padding: 40px 0 0;
	font-size: 1.5rem;
	color: #F9F9F9;
	.footer-logo {
		margin: 0 0 25px;
		&__link {
			display: block;
			width: 210px;
			margin: 0 auto 15px;
		}
	}
	.footer-address {
		margin: 0 0 65px;
		&__link {
			color: #F9F9F9;
		}
	}

	.copyright {
		background: $baseColor;
		border-top: 1px solid #1F8576;
		padding: 20px 0;
		color: $white;
		font-size: $s;
	}
}