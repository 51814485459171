@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	.header-top {
		text-align: right;
		height: 40px;
		background: $orangeLight;
		line-height: 40px;
		font-size: $l;
		padding: 0 30px;
	}
	width: 100%;
	
	.inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 80px;
		margin: 0 auto;
		padding: 0 35px;
	}

	.header-logo {
		flex: 0 1 215px;
	}
	.header-contact {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $baseColor;
		&__tel {
			font-size: 3.0rem;
			font-weight: $font-bold;
			line-height: 1.0;
			margin: 0 20px 0 0;
		}
		.btn i {
			margin-right: 5px;
		}
	}
}


