@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	.row {
		&__inner {
			max-width: 1040px;
			margin: 0 auto;
			padding: 70px 20px;
			&--narrow {
				padding: 70px;
			}
		}
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
.bg-blue {
	background: $blue;
}
.bg-orange {
	background: #f8ad3b;
}
.bg-orangelight {
	background: $orangeLight;
}
/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}
a[href^="tel:"] {
	cursor: default;
	text-decoration: none;
	color: $textColor;
	&:hover {
		opacity: 1.0;
	}
}

/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 170px;
	background: $baseColor;
	background-size: cover;
	font-size: 2.8rem;
	font-weight: $font-medium;
	text-align: center;
	line-height: 1.2;
	color: $white;
	
	@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 17vh; }
}

.ttl02 {
	font-size: $xxxl;
	font-weight: $font-medium;
	text-align: center;
	padding: 0 0 15px;
	margin: 0 0 30px;
	position: relative;
	&::after {
		content: "";
		width: 100px;
		border-bottom: 3px solid $baseColor;
		@include centering-elements(true, false);
		bottom: 0;
	}
}

.ttl03 {
	margin: 0 0 20px;
	padding: 7px 15px;
	font-size: $xl;
	font-weight: $font-medium;
	background: $baseColor;
	color: $white;
	line-height: 1.4;
}

.ttl04 {
	margin: 0 0 10px;
	font-size: 1.7rem;
	font-weight: $font-medium;
	line-height: 1.4;
	color: $baseColor;
}

.ttl05 {
	margin: 0 0 20px;
	padding: 0 0 10px;
	border-bottom: 1px solid #6FB7AC;
	font-size: $l;
	font-weight: $font-medium;
	line-height: 1.4;
}

.ttl06 {
	margin: 0 0 15px;
	color: $baseColor;
	font-size: $m;
	font-weight: bold;
	line-height: 1.4;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -15px;
	 margin-right: -15px;

	 &> * {
		 margin-left: 15px;
		 margin-right: 15px;
		 width: calc((100% - 61px) / 2 );
	 }
	&--narrow {
		margin-left: -10px;
		margin-right: -10px;

		&> * {
			margin-left: 10px;
			margin-right: 10px;
			width: calc((100% - 41px) / 2 );
		}
	}
 }

.column3 {
	margin-left: -20px;
	margin-right: -20px;

	&> * {
		margin-left: 20px;
		margin-right: 20px;
		width: calc((100% - 121px) / 3);
	}
	@media screen and (min-width:  767.5px) and (max-width:1024px) {
		margin-left: -15px;
		margin-right: -15px;
		&> * {
			margin-left: 15px;
			margin-right: 15px;
			width: calc((100% - 91px) / 3);
		}
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--green {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--gray {
		background: #f9f9f9;
	}

	&--border-gray {
		border: 1px solid $borderColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 15px 20px;
		background: $white;
		border: 1px solid $borderColor;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-orangelight {
		background: $orangeLight;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}
	.column {
		max-width: 700px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 500px;}
	}
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 350px;
	height: 70px;
	margin: 0 5px;
	padding: 0 30px;
	color: $white;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	cursor: pointer;
	font-size: $m;
	transition: 0.3s all;
	@include radius(35);

	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }
	
	&:hover {
		text-decoration: none;
	}
	&--green {
		background: $baseColor;
		border: 1px solid $baseColor;
		&:hover {
			color: $baseColor;
			background: $white;
		}
	}
	&--orange {
		background: $orange;
		border: 1px solid $orange;
		&:hover {
			color: $orange;
			background: $white;
		}
	}
	&--blue {
		background: $blue;
		border: 1px solid $blue;
		&:hover {
			color: $blue;
			background: $white;
		}
	}
	&--red {
		background: $red;
		border: 1px solid $red;
		&:hover {
			color: $red;
			background: $white;
		}
	}
	&--back {
		color: #666;
		border: 1px solid #666;
		background: $white;
		&:hover {
			opacity: 0.7;
		}
	}
	&--lg {
		min-width: 500px;
		height: 80px;
		@include radius(40);
		font-size: $xl;
	}
	&--md {
		min-width: 230px;
		height: 50px;
	}
	&--sm {
		min-width: inherit;
		height: 40px;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			text-decoration: none;
			@include radius(3);
			
			&.current,
			&:hover {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.form-flow {
	font-size: 1.5rem;
	display: flex;
	margin: 0 70px 30px;
	&__item {
		position: relative;
		display: block;
		width: auto;
		margin: 0;
		padding: 0;
		text-align: center;
		text-overflow: ellipsis;
		color: #ccc;
		flex: 1;
		&__icon {
			display: block;
			width: 15px;
			height: 15px;
			margin: 0 auto 10px;
			border-radius: 50%;
			background-color: #ccc;
			&::after,
			&::before {
				position: absolute;
				top: 6px;
				display: block;
				width: 100%;
				height: 3px;
				content: '';
				background-color: #ccc;
			}
		}
		&--completed {
			color: #666;
			.form-flow__item__icon {
				background: #666;
				&::after,
				&::before {
					background: #666;
				}
			}
		}
		&--active {
			color: $baseColor;
			.form-flow__item__icon {
				font-weight: $font-bold;
				background: $baseColor;
				&::after,
				&::before {
					background: $baseColor;
				}
			}
		}
		&:last-child {
			.form-flow__item__icon {
				&::after,
				&::before {
					width: 0%;
					margin-right: 50%;
				}
			}
		}		
	}
	&--car {
		.form-flow__item--active {
			color: $blue;
			.form-flow__item__icon {
				background: $blue;
				&::after,
				&::before {
					background: $blue;
				}
			}
		}
	}
	&--seimei {
		.form-flow__item--active {
			color: $orange;
			.form-flow__item__icon {
				background: $orange;
				&::after,
				&::before {
					background: $orange;
				}
			}
		}
	}
}

.label-must {
	right: 10px;
	padding: 2px 10px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
	@media screen and (min-width:  767.5px) and (max-width:1024px) { right: 0;}
	&--car {
		background: $blue;
	}
	&--seimei {
		background: $orange;
	}
}

.note {
	font-size: $s;
	color: #717171;
	margin: 5px 0;
}

.error-block {
	margin-top: 5px;
	font-weight: $font-medium;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}

@include placeholderColor(#bbb);
	
textarea:-ms-input-placeholder {
	color: #bbb;
}

/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	margin-bottom: 5px;
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 80px;
	height: 25px;
	background: $baseColor;
	color: #fff;
	font-size: $xxs;
	text-align: center;
	line-height: 25px;
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 40px;
	border-top: 1px solid $borderColor;
	
	&__item {
		display: flex;
		align-items: center;
		padding: 15px 0;
		border-bottom: 1px solid $borderColor;
		
		&__date,
		&__label {
			flex: 0 0 auto;
			margin: 0 20px 0 0;
		}
		&__link {
			flex: 1 1 auto;

			.icon-filter_none {
				color: $baseColor;
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 40px;
	}
	&__block {
		padding: 0 0 50px;
		border-bottom: 1px solid $borderColor;
	}
}




/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	&__ttl {
		&__link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 120px;
			padding: 5px 0 0;
			background: $textColor;
			color: $white;
			font-size: $l;
			text-align: center;
			
			@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 11.8vh; }

			&::before {
				top: 0;
				content: '';
				width: 50px;
				height: 5px;
				background: $baseColor;
				@include centering-elements(true, false);
			}
		}
	}
	&__item {
		display: flex;
		align-items: center;
		padding: 20px 15px;
		border: 1px solid $borderColor;
		border-top: none;

		&__text {
			flex: 1 1 auto;
			font-size: $s;
		}
		.icon-angle-right {
			flex: 0 0 auto;
			margin: 0 0 0 10px;
			font-size: $xxs;
		}
		&:hover {
			opacity: 1;
			background: #f0f0f0;
		}
	}
}
