@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	.header-top {
		text-align: right;
		height: 30px;
		background: $orangeLight;
		line-height: 30px;
		font-size: $m;
		padding: 0 15px;
	}
	.inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 60px;
		padding: 0 15px;
	}

	.header-logo {
		flex: 1 1 auto;
		line-height: 0;
	}
	
	.header-contact {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $baseColor;
		&__tel {
			font-size: 3.0rem;
			font-weight: $font-bold;
			line-height: 1.0;
			margin: 0 20px 0 0;
		}
		.btn {
			min-width: 100%;
			height: 35px;
			padding: 0 20px;
			i {
				right: 5px;
			}
		}
	}
}

