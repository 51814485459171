@charset "utf-8";
/* ============================================================ */
/* seimei-form.html */
/* ============================================================ */
.seimei-form {
	.hosyo-box {
		max-width: 660px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 470px; }
	}
	.hosyo-list {
		&__item {
			background: $white;
			text-decoration: none;
			color: $textColor;
			position: relative;
			padding: 15px 40px 15px 15px;
			transition: 0.3s all;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 10px 30px 10px 10px; }
			&__img {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin: 0 0 10px;
				&__icon {
					width: 37px!important;
					height: 43px;
					margin: 0 10px 0 0;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 30px!important;height: 35px; }
				}
				&__logo {
					height: 25px;
					width: auto!important;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 16px; }
					&--life {
						height: 32px;
						@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 21px; }
					}
				}
			}
			.icon-angle-right {
				background: $baseColor;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
				@include centering-elements(false, true);
				right: 15px;
				font-weight: $font-bold;
				font-size: $s;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { right: 10px; }
			}
			&:hover {
				background: #E4F2DD;
				.hosyo-list__item__img__logo,
				hosyo-list__item__img__icon {
					opacity: 1.0;
				}
			}
			&__text {
				margin-bottom: 0;
			}
		}
	}
}